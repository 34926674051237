.auth-header{
    /* width: 100%; */
    color: '#001C5D';
    display: flex;
    /* justify-content: space-between; */
    align-items: center;
    /* padding: 10px 0; */
    z-index: 10;
}

.logo{
    width: 168px;
}

.auth-header ul li{
    display: inline-block;
    padding: 0 10px;
    font-size: 24px;
 
}

.menu-icon{
    width: 30px;
    display: none;
}

.header-menu{
    display: flex;
    align-items: center;
    justify-content: center;
}

.hide-ul{
    display: inline;
}

.mobile-menu{
    display: none;
}

.mobile-menu-list{
    display: none;
}
.desktop-menu-list{
    display: none;
}



/*------------------------- media query------------------ */

/* @media (max-width:800px){

    .logo{
        width: 170px;
        transition: 0.4s;
        padding: 10px;
    }

    .auth-header ul li{
        padding: 0 6px;
    }

} */

@media (max-width:720px){
    .logo{
        width: 100px;
    }
    .auth-header ul{
        position: fixed;
        top: 0;
        right: 0px;
        bottom: 0;
        background-color: '#001C5D';
        transition: 0.5s;
    }
    .auth-header ul li{
        display: block;
        padding: 10px;
    }

    .menu-icon{
        display: block;
        z-index: 12;
        margin-left: 15px;
        margin-right: 15px;
    }

    .hide-ul{
        display: none;
    }
    .mobile-menu{
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .mobile-menu-list {
        z-index: 9999;
        color: white;
        background-color: #0095B9;
        position: fixed;
        bottom: 0;
        right: 0;
        top: 0;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100dvh;
       
    }
    .mobile-menu-list ul {
        padding-top: 15px; 
        width: 100%;
        display: block;
    }
    
    .mobile-menu-list li {
        font-size: 36px;
        font-weight: 500;
        padding: 0;
    }

        /* .header-menu{
            flex-direction: row;
        } */
}


