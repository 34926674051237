body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
/* p {
   color: red;
} */

@media (min-width: 960px) {
  .mobile_menu {
    display: none !important;
  }
  .custom-wrapper-mobile {
    display: none !important;
  }
  .mobile-view,
  .mobile-display-none {
    display: none !important;
  }
  .mobile-display-none {
    display: none !important;
  }
}

@media (max-width: 960px) {
  .web_menu {
    display: none !important;
  }
  .assignment-toogle {
    width: 100% !important;
  }
  .resources {
    display: none !important;
  }
  .countdown-wrapper {
    display: none !important;
  }
  .web-view {
    display: none !important;
  }
  .adli-container {
    height: auto !important;
    padding-left: 5%;
    padding-right: 5%;
  }
  .order-md-1 {
    max-height: fit-content !important;
  }
}
/* tablet screen size */
@media (max-width: 740px) {
}
@media (min-width: 740px) {
}

.between {
  justify-content: space-between;
}

.thin {
  font-weight: 400;
}
.thick {
  font-weight: 600;
}
.ultra-thick {
  font-weight: 700;
}
