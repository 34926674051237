/* .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 30px 0;
    background-color: white;
    color: black;
    z-index: 10;
    box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
    width: 100%;
  } */

  .adli-navbar{
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: black;
    z-index: 10;
    box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
    /* width: 100%; */
    padding-top: 20px;
    padding-bottom: 20px;
    background-color: white;
    position: fixed;
    top:0;
    left: 0;
    right: 0;
  }

  li {
    list-style: none;
  }
  
  .logo {
    font-size: 24px;
  }
  .adli-nav{
    display: flex;
    align-items: center;
  }

  .adli-nav ul{
    display: flex;
    align-items: center;
  }

  .adli-nav ul li{
    display: inline-block;
    padding-left: 20px;
  }

  
  .sidebar {
    position: fixed;
    top: 0;
    right: 0;
    width: 200px;
    height: 100%;
    background-color: #333;
    color: white;
  }
  
  .sidebar-list {
    padding: 0;
    list-style-type: none;
  }
  
  .sidebar-item {
    padding: 10px;
    cursor: pointer;
  }
  
  .sidebar-item:hover {
    background-color: #555;
  }
  
  .sidebar-toggle,.close-sidebar {
    display: none; 
  }

  @media (max-width: 768px) {
    .nav {
      display: none; 
    }
  
    .sidebar-toggle {
      display: block; 
      font-size: 24px;
      background-color: transparent;
      color: white;
      border: none;
      cursor: pointer;
    }
  
    .close-sidebar {
      display: block; 
      position: fixed;
      top: 0;
      right: 5px;
    }
  }
  
 
  