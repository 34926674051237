@import url(./assets/fonts/satoshi.css);
@import url(../node_modules/bootstrap/dist/css/bootstrap.css);

$primary-color: #0095b9;
$secondry-color: #001c5d;
$tertiary-color: #c5007e;
$white-color: #ffffff;
$app-background-color: "#fafafa";
$days-timer-background-color: #008ab4;
$current-assess-text: #b5007c;
$disable-color: #cacaca;
$form-background-color: #fbfbfb;
$community-color: #747474;
$app-bg-color:#fafafa;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

#zohohc-asap-web-launcherbox {
  bottom: 80px !important;
}

body {
  margin: 0;
  font: "Satoshi";
  font-family: "Satoshi Bold Italic", "Satoshi Bold", "Satoshi-Regular",
    "Satoshi Light", "Satoshi Light Italic", "Satoshi Regular", "Satoshi Italic",
    "Satoshi Medium", "Satoshi Medium Italic", "Satoshi Black",
    "Satoshi Black Italic";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-y: scroll !important;
  padding: 0 !important;
  background-color: #fafafa !important;
  // background: transparent;
}

.app-bg-color{
  background-color: $app-bg-color;
}

p {
  color: $secondry-color;
  font-family: "Satoshi-Regular";
  font-size: 16px;
  font-weight: 400;
  line-height: 21.6px;
  text-align: left;
}

ul {
  margin: 0;
}

a {
  text-decoration: underline;
  font-size: 18px;
  cursor: pointer;
  color: $secondry-color;
  font-family: "Satoshi-Regular";
}
h4 {
  margin: 0;
}
h5 {
  font-family: "Satoshi-Regular";
}

.description {
  font-size: 16px;
  font-weight: 400px;
  line-height: 22px;
}

.loader {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
}

.footer {
  background-color: $secondry-color;
  display: flex;
  // align-items: center;
  justify-content: space-evenly;
  padding: 15px 0;
  height: 90px;
  z-index:10 ;
  p {
    color: $white-color;
  }
}

.mobile-footer{
background-color: $secondry-color;
display: flex;
align-items: center;
justify-content: center;
padding: 15px 0;
flex-direction: column;
height: 90px;
z-index:10 ;
р {
color: $white-color;
}
}

.invalid-input {
  color: red;
}

.input-is-invalid {
  border: 1px solid red;
}

.button-primary {
  background-color: $primary-color;
  font-weight: 400;
  font-size: 20px;
  padding: 15px 20px;
  border-radius: 30px;
  outline: none;
  border: 0;
  width: 100%;
  max-width: 480px;
  // min-width: 280px;
  color: white;
  margin: 20px 0;
  height: 55px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.button-disable {
  background-color: $disable-color;
  font-weight: 400;
  font-size: 20px;
  padding: 15px 20px;
  border-radius: 30px;
  outline: none;
  border: 0;
  width: 100%;
  max-width: 480px;
  // min-width: 280px;
  color: white;
  margin: 20px 0;
  height: 55px;
  cursor: not-allowed;
}

.button-primary1 {
  background-color: $primary-color;
  font-size: 20px;
  padding: 15px 20px;
  border-radius: 30px;
  outline: none;
  border: 0;
  width: 255px;
  height: 55px;
  color: white;
  margin: 20px 0;
  text-align: center;
  line-height: 20px;
}

.button-secondry {
  background-color: white;
  font-weight: 400;
  font-size: 20px;
  padding: 15px 20px;
  border-radius: 30px;
  outline: none;
  border: 0;
  width: 100%;
  max-width: 480px;
  min-width: 280px;
  color: $primary-color;
  margin: 10px 0;
  border: 1px solid $primary-color;
  height: 55px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.walkthrough-img{
width: 100%;
}
.button-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.close-button {
  float: right;
  border: none;
  background-color: #ffffff;
  margin: 15px;
  display: block;
}

.adli-container {
  padding-left: 10%;
  padding-right: 10%;
}

// .adli-container div{
//   // flex-basis: 45%;
// }

.input {
  width: 90%;
}

.login {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 78vh;
}

.common-adli {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 78vh;
}

.common-container {
  width: 100%;
  max-width: 520px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 28px;
  margin-bottom: 28px;
}

.confirmText {
  font-family: "Satoshi-Bold";
  font-size: 28px;
  line-height: 37.8px;
  color: $secondry-color;
  text-align: center;
  font-weight: 500px;
}

.login-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  // align-items: center;
}

.title {
  color: $secondry-color;
  font-family: "Satoshi-Bold";
  font-size: 52px;
  line-height: 70.2px;
  font-weight: 500;
}
.quesTitle {
  color: $secondry-color;
  font-family: "Satoshi-Bold";
  font-size: 25px;
  line-height: 33.75px;
  font-weight: 500;
}

.nullData {
  color: $secondry-color;
  font-family: "Satoshi-Bold";
  font-size: 40px;
  line-height: 54px;
  font-weight: 500;
}

.previousContentTitle {
  color: $secondry-color;
  font-family: "Satoshi-Bold";
  font-size: 35px;
  line-height: 47.25px;
  font-weight: 700;
}

.getVerifiedTitle {
  color: $secondry-color;
  font-family: "Satoshi-Regular";
  font-size: 18px;
  line-height: 24.3px;
  font-weight: 400;
  width: 292px;
  height: 57px;
  text-align: center;
  margin-top: 10px
}

.importantMessageTitle {
  color: $secondry-color;
  font-family: "Satoshi-Regular";
  font-size: 18px;
  line-height: 24.3px;
  font-weight: 400;
  padding-left: 10px;
  padding-right: 10px;
  min-height: 100px;
}
.Welcometitle {
  color: $secondry-color;
  font-family: "Satoshi-Bold";
  font-size: 41px;
  line-height: 55.35px;
  font-weight: 500;
}
.mini-title {
  color: $secondry-color;
  font-family: "Satoshi-Regular";
  font-size: 40px;
  line-height: 54px;
  font-weight: 500;
}

.loginTitle {
  color: $secondry-color;
  font-family: "Satoshi-Bold";
  font-size: 45px;
  line-height: 60.75px;
  font-weight: 700;
}
.loginDesc{
  color: $secondry-color;
  font-family: "Satoshi-Regular";
  font-size: 16px;
  line-height: 21.6px;
  font-weight: 400;
}

.sub-title {
  font-family: "Satoshi-Regular";
  font-size: 20px;
  line-height: 27px;
  color: $secondry-color;
}

.questionNumber{
  color: $current-assess-text;
  font-family: 'Satoshi-Regular';
font-size: 22px;
line-height: 29.7px;
}

.questions{
  font-family: 'Satoshi-Regular';
font-size: 25px;
font-weight: 500;
line-height: 33.75px;
color:#21335F

}

.otp-subtitle {
  font-family: "Satoshi-Regular";
  font-size: 20px;
  line-height: 27px;
  color: $secondry-color;
  text-align: center;
  font-weight: 400px;
}

.left {
  width: 100%;
  display: flex;
  align-items: start;
  flex-direction: column;
}

.common-left {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.right {
  width: 100%;
}

.right img {
  width: 100%;
  height: 100%;
}

.input-f {
  width: 100%;
  // min-width: 320px;
}

.input-box {
  width: 100%;
  // max-width: 450px;
  margin: 10px 0;
  height: 44px;
  border-radius: 50px;
  outline: none;
  border: 1px solid $secondry-color;
  padding: 10px 28px;
  font-family: "Satoshi-Regular";
  font-size: 17px;
}

.thankyouTitle {
  color: $secondry-color;
  font-family: "Satoshi-Bold";
  font-size: 29px;
  line-height: 39.15px;
  font-weight: 500;
}

.input-box1 {
  width: 100%;
  max-width: 290px;
  min-width: 200px;
  height: 44px;
  border-radius: 40px;
  outline: none;
  border: 1px solid $secondry-color;
  padding: 10px 20px;
  // opacity: 0.6;
  font-family: "Satoshi-Regular";
  font-size: 17px;
  font-weight: 400;
  line-height: 22.95px;
  text-align: left;
  color: $secondry-color;
  margin-bottom: 10px;
}
.input-box2 {
  width: 100%;
  min-width: 200px;
  height: 44px;
  border-radius: 40px;
  outline: none;
  border: 1px solid $secondry-color;
  padding: 10px 10px;
  // opacity: 0.6;
  font-family: "Satoshi-Regular";
  font-size: 17px;
  font-weight: 400;
  line-height: 22.95px;
  text-align: left;
  color: $secondry-color;
  margin-bottom: 10px;
}

// .input-box1 {
//   width: 100%;
//   max-width: 290px;
//   min-width: 370px;
//   height: 44px;
//   border-radius: 40px;
//   outline: none;
//   border: 1px solid #001c5d;
//   padding: 10px 10px;
//   font-family: "Satoshi-Regular";
//   font-size: 17px;
//   font-weight: 400;
//   line-height: 22.95px;
//   text-align: left;
//   color: #001c5d;
//   margin-bottom: 10px;
// }

.adli-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.thanksTitle{
  font-family: "Satoshi-Bold";
  font-size: 18px;
  line-height: 24.3px;
  color: $tertiary-color;
  font-weight: 500px;
  margin-bottom: 0px;
}
 .thanksSubTitle{
  font-family: "Satoshi-Regular";
  font-size: 18px;
  line-height: 24.3px;
  color: $tertiary-color;
  font-weight: 400px;
 }
////
.forgotModal {
  padding: 20px;
}

.daysTimer {
  height: 80px;
  width: 80px;
  border: 2px solid $days-timer-background-color;
  margin-right: 20px;
  border-radius: 15px;
  display: flex;
  justify-content: center;
}

.daysTimer-mobile {
  height: 60px;
  width: 60px;
  border: 2px solid $days-timer-background-color;
  border-radius: 15px;
  margin-right: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.countdown-wrapper {
  height: auto;
  border: none;
  align-content: center;
  background-color: #ffff;
  padding: 0px 100px;
}

.carousel-inner {
  border-radius: 50px;
  height: 100%;
  width: 90%;
}

.assignment-toogle {
  border: 1px solid transparent;
  width: 50%;
}
.assignment-full {
  border: 1px solid transparent;
  width: 100%;
}

.my-profile {
  border: 1px solid transparent;
  width: 100%;
}

.nav-tabs {
  justify-content: center;
}

.tab {
  overflow: hidden;
  border: 1px solid $secondry-color;
  background-color: #ffffff;
  border-radius: 10px;
}
.account-tab {
  overflow: hidden;
  border: 1px solid $secondry-color;
  background-color: #ffffff;
  border-radius: 10px;
  min-height: 34px;
  display: flex;
}

.tab button {
  background-color: inherit;
  float: left;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 7px 16px;
  transition: 0.3s;
  width: 50%;
  color: $secondry-color;
  font-size: 14px;
  font-weight: 600;
}

.account-tab button {
  background-color: inherit;
  float: left;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 7px 8px;
  transition: 0.3s;
  // width: 25%;
  color: $secondry-color;
  font-size: 14px;
  font-weight: 600;
  flex: 1;
  border-right: 1px solid $secondry-color;
}

.account-tab.has-4-buttons button {
  width: 25%;
}

.account-tab.has-5-buttons button {
  width: 20%;
}

.account-tab button:last-child {
  border-right: none;
}

.tab button:hover {
  background-color: #ddd;
}

.tab button.active {
  background-color: $secondry-color;
  color: #ffffff;
  font-size: 14px;
  font-weight: 600;
}

.account-tab button.active {
  background-color: $secondry-color;
  color: #ffffff;
  font-size: 14px;
  font-weight: 600;
}

.assess-helper-text {
  color: $current-assess-text !important;
}

.forget-modal-container {
  background: rgba(0, 0, 0, 0.7);
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 100;
  display: none;
  justify-content: center;
  align-items: center;
}

.forget-modal {
  background-color: white;
  border-radius: 30px;
  max-width: 400px;
  max-height: 392px;
  margin-top: 90px;
}

.hide-forget-modal {
  display: flex;
  justify-content: center;
  align-items: center;
}

///bootstrap class changes

// .row{
//   --bs-gutter-x:0;
// }
.row {
  --bs-gutter-x: 0;
}

.braintip-box {
  // width: '465px', height: '289px', border: '1px solid #c5007e', borderRadius: '50px', marginLeft: '108px' }
  width: 465px;
  height: 289px;
  border: 1px solid $tertiary-color;
  border-radius: 50px;
  margin-left: 108px;
}
@media(max-width:1024px){
  .braintip-box{
    margin-left: 0;
  }
}
@media(max-width:768px) and (min-width: 576px){
  .braintip-box{
    margin-left: 0;
    width: auto !important;;
    height: 289px;
    border: 1px solid #c5007e;
    border-radius: 50px;
  }
  .braintip-slider-box {
    flex: 1 1;
    justify-content: flex-end;
    align-items: flex-end;
    width: auto !important;
    height: 23px;
    margin-left: 90px !important;
    margin-top: -50px;
}
}

.braintip-slider-box {
  // style={{ flex: 1, justifyContent: 'flex-end', alignItems: 'flex-end', width: '252px', height: '23px', marginLeft: '150px', marginTop: '-50px' }}
  flex: 1;
  justify-content: flex-end;
  align-items: flex-end;
  width: 252px;
  height: 23px;
  margin-left: 110px;
  margin-top: -50px;
}

.braintip-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.questionnaire {
  width: 100%;
  height: auto;
  border: none;
  padding: 20px 60px;
  border-top-right-radius: 15px;
  border-top-left-radius: 15px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  border-bottom: 5px solid $secondry-color;
  // background-color: red;
  background-color: rgb(255 255 255 / 50%);


  .header {
    font-size: 45px;
    font-weight: 400;
    line-height: 54.46px;
    text-align: left;
    color: $secondry-color;
  }
  .options {
    font-size: 22px;
    font-weight: 400;
    text-align: left;
    color: $secondry-color;
    display: flex;
    align-items: center;
  }
}

// .react-date-picker {
//   display: inline-flex; 
//   line-height: 42px;
//   position: relative;
// }

// /////======================= media query
@media (max-width: 670px) {
  .title {
    font-size: 30px;
  }
  .adli-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .input-f {
    padding-right: 5%;
    padding-left: 5%;
  }

  .mobile-footer{
    height: auto;
  }
  .walkthrough-img{
    margin-top: 80px;
  }

  .description {
    padding-left: 0%;
    padding-right: 0%;
    font-size: 16px;
    font-weight: 400px;
    line-height: 21.6px;
    font-family: "Satoshi-Regular";
  }
  .braintip-box {
    width: auto;
    height: 289px;
    border: 1px solid $tertiary-color;
    border-radius: 50px;
    margin-left: auto;
    display: flex;
    justify-content: center;
    // align-items: center;
  }
  .braintip-header-wrapper {
    // {{ display: 'flex', flexDirection: 'column', height: '100%' }}
    // style={{ flex: 1, display: 'flex', justifyContent: 'flex-start', alignItems: 'center'}}
    display: flex;
    flex: 1;
    justify-content: flex-start;
    align-items: center;
    // flex-direction: column;
    // height: 100%;
    // flex: 1;
  }

  .braintip-img {
    // style={{paddingTop:'40px',marginLeft:'40px'}}
    padding-top: 40px;
    margin-left: -20px;
  }
  // .braintip-title{

  // }
  .braintip-slider-box {
    margin-left: 30px;
  }
  .questionnaire {
    padding: 0;
    box-shadow: none;
  }
}

.profile-form {
  width: 100%;
  max-height: 640px;
  min-height: 640px;
  border: none;
  padding: 20px 60px;
  border-top-right-radius: 15px;
  border-top-left-radius: 15px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  // background-color:whi;
  border-bottom: 5px solid $secondry-color;
  background-color: rgba(255, 255, 255, 0.5);
  overflow-y: auto;
  opacity: 0.9;
}

.profile-form .header {
  font-size: 45px;
  font-weight: 400;
  line-height: 54.46px;
  text-align: left;
  color: $secondry-color;
}

.profile-form::-webkit-scrollbar {
  width: 1px;
  background-color: #ffffff;
}

.profile-form::-webkit-scrollbar-track {
  height: 4px;
}

.profile-form::-webkit-scrollbar-thumb {
  background: #ffff;
  height: 24px;
  width: 8px;
  border-radius: 25px;
}

.profile-form::-webkit-scrollbar-thumb:hover {
  background:#ffffff;
}

.full-img {
  min-height: 740px;
  width: 100%;
}
.fit-img {
  height: 100%;
  width: 100%;
}

.fit-img1 {
  // height: 100%;
  width: 400px;
  top: 40px;
  left: -300px;
  object-fit: contain;
  z-index: -1;
}

.caregiver-item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.form-label {
  color: $secondry-color;
  font-size: 20px;
}

.form-labelmfa {
  color: $community-color;
  font-size: 14px;

}

.header {
  font-family: "Satoshi-Bold";
  font-size: 40px;
  font-weight: 500;
  line-height: 54px;
  text-align: left;
  color: $secondry-color;
}

.content {
  font-family: "Satoshi-Regular";
  font-size: 22px;
  font-weight: 500;
  line-height: 29.7px;
  text-align: left;
  color: $secondry-color;
}


.contentTitle {
  font-family: "Satoshi-Regular";
  font-size: 35px;
  color: $secondry-color;
}

.contentTitleAssessment {
  font-family: "Satoshi-Regular";
  font-size: 35px;
  color: $secondry-color;
  font-weight: 400;
  line-height: 42.36px;
}
.contentsubTitleAssessment{
  font-family: "Satoshi-Regular";
  font-size: 18px;
  color: $secondry-color;
  font-weight: 400;
  line-height: 24.3px;
}

.subTitleAssessment{
  font-family: "Satoshi-Regular";
  font-size: 18px;
  color: $tertiary-color;
  font-weight: 400;
  line-height: 24.3px;
}

.mobile-subTitle {
  font-family: "Satoshi-Bold";
  font-size: 21px;
  font-weight: 500;
  color: $secondry-color;
}
.mobile-content {
  font-family: "Satoshi-Regular";
  font-size: 14px;
  font-weight: 500;
  line-height: 18.9px;
  color: $secondry-color;
}

.left-back-img {
  position: absolute;
  left: -20%;
  bottom: 0px;
  z-index: -1;
  width: 50%;
}
.right-back-img {
  position: absolute;
  bottom: 0px;
  z-index: -1;
  width: 50%;
}

.checkbox-radio {
  height: 25px;
  width: 25px;
  margin-right: 40px;
}

.score-box {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  border: 1px solid $primary-color;
  margin-top: 10px;
  margin-bottom: 20px;
  padding: 5px;
  border-radius: 10px;
  height: 62px;
}
.user-guides-tab {
  border: 1px solid $secondry-color;
  border-radius: 10px;
  background-color: $secondry-color;
  outline: none;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  padding: 7px 8px;
  color: #ffffff;
  text-align: center;
  height: 34px;
}

.left-back-img-user {
  background-image: url("./assets/images/4_Assessment\ \(Bush\)_Web\ App.png");
}

.react-date-picker__wrapper {
  font: "Satoshi";
  border: none;
  width: 100%;
  height: 42px;
  border-radius: 40px;
  outline: none;
  border: 1px solid $secondry-color !important;
  padding: 0px 20px !important;
  font-size: 16px;
  // margin: 10px 0;
}

.react-date-picker {
  padding: 0px !important;
  display: inline-flex;
  position: relative;
  z-index: 5;
}

.react-date-picker1 {
  padding: 0px !important;
}

.custom-form-input-date {
  font: "Satoshi";
  width: 100%;
  height: 42px;
  border: none;
  border-radius: 40px;
  font-size: 16px;
}
.custom-form-input-date1 {
  font: "Satoshi";
  width: 100%;
  height: 44px;
  border: none;
  border-radius: 50px;
  font-size: 18px;
}

.form-control.is-invalid {
  background-image: none !important;
}
.form-control.is-valid {
  background-image: none !important;
  border: 2px solid $secondry-color !important;
}

.previous-assessment-label {
  color: $tertiary-color;
  line-height: 10px;
  font-family: 'satoshi-Regular';
  font-size: 14px;
  font-weight: 400px;
}

.previous-assessment-text {
  color: $secondry-color;
  line-height: 10px;
  font-family: 'satoshi-Regular';
  font-size: 14px;
  font-weight: 400px;
}

.button-secondary {
  background-color: white;
  font-weight: 400;
  font-size: 20px;
  padding: 10px 20px;
  border-radius: 30px;
  outline: none;
  border: 0;
  color: $primary-color;
  margin: 10px 0;
  border: 1px solid $primary-color;
  height: auto;
  width: 50%;
}

.community-detail {
  color: $community-color;
  font-family: "Satoshi-Regular";
  font-size: 16px;
  font-weight: 400;
}

.inviteSubTitle{
  font-size: 17px;
  font-weight: 400;
  line-height: 22.95px;
  color: $secondry-color;
  font-family: 'satoshi-Regular';
}
.inviteTitle{
  font-size: 28px;
  font-weight: 500;
  line-height: 37.8px;
  color: $secondry-color;
  font-family: 'satoshi-Bold';
}

.inviteTopTitle{
  font-size: 17px;
  font-weight: 500;
  line-height: 22.95px;
  color: $tertiary-color;
  font-family: 'satoshi-Bold';
}

.loader {
  border: 10px solid $secondry-color;
  border-top: 10px solid rgb(166, 49, 123);
  border-radius: 50%;
  width: 80px;
  height: 80px;
  animation: spin 2s linear infinite;
  margin: auto;
  left: 0;
  right: 0;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.txt p {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

hr {
  border: 2px solid $secondry-color;
}

@media (min-width: 960px) {
  .order-md-1::-webkit-scrollbar {
    width: 1px;
    background-color: $app-background-color;
  }

  .order-md-1::-webkit-scrollbar-track {
    height: 4px;
  }

  .order-md-1::-webkit-scrollbar-thumb {
    background: $primary-color;
    height: 24px;
    width: 8px;
    border-radius: 25px;
  }

  .order-md-1::-webkit-scrollbar-thumb:hover {
    background: $primary-color;
  }
}

.content-scroll {
  height: 400px;
  overflow-y: auto;
}

.content-scroll::-webkit-scrollbar {
  width: 1px;
  background-color: $app-background-color;
}

.content-scroll::-webkit-scrollbar-track {
  height: 4px;
}

.content-scroll::-webkit-scrollbar-thumb {
  background: $primary-color;
  height: 24px;
  width: 8px;
  border-radius: 25px;
}

.content-scroll::-webkit-scrollbar-thumb:hover {
  background: $primary-color;
}

.time{
  color: $secondry-color;
  font-size: 14px;
}
.read{
  color: $tertiary-color;
  font-size: 16px;
}